#root {
  background-color: #a6adc1;
}
.App {
  padding: 0;
}
.main-holder {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  iframe {
    width: calc(100% - 2px);
    height: calc(100% - 68px);
    border: 1px solid white;
    background-color: white;
  }
}
h1 {
  margin: 0 0 1.1em 0;
  font-weight: 500;
  font-size: 28px;
  color: #92423e;
}
p {
  margin: 0;
}
.bold {
  font-weight: 500;
}
.App-header-module {
  display: flex;
  height: 96px;
  background-color: #fff;
  border-bottom: 2px solid #e7c072;
  box-shadow: 0 0px 3px 2px rgb(0 0 0 / 17%), 0 0px 5px 0px rgb(0 0 0 / 26%), 0 0px 17px 1px rgb(0 0 0 / 35%);
  a {
    height: 100%;
    display: inline-flex;
    width: 366px;
    height: 77px;
    vertical-align: middle;
    margin: auto 0 auto 26px;
  }
}
.App-header {
  position: absolute;
  top: 0;
  left: auto;
  right: 35px;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: end;
  vertical-align: middle;
  justify-content: center;
  .k-menu-horizontal .k-item .k-link {
    padding: 0;
  }
  .k-item {
    background-color: transparent;
    color: #424242 !important;
    transition: all .35s ease;
    border: 1px solid #e4e4e4;
    margin-right: -1px;
    padding: 10px 24px;
  }
  .k-menu:not(.k-context-menu) > .k-item:hover, .k-menu:not(.k-context-menu) > .k-item.k-hover, .k-menu-horizontal .k-item:hover {
    color: white !important;
    background-color: #663230;
    border-color: #663230;
  }
}
.App-content {
  position: absolute;
  margin: 17px;
  width: calc(100% - 34px);
  height: calc(100% - 130px);
}
.download-page, .upload-page {
  padding: 17px;  
  .download-flex, .upload-flex {
    display: flex;
    width: 100%;
    .download-form {
      width: 44%;
      margin-right: 44px;
    }
    .upload-form {
      width: calc(44% + 72px);
      margin-right: 44px;
    }
    .download-options, .upload-options {
      width: 26%;
    }
  }
}
.host-page {
  background-color: white;
  position: absolute;
  top: 34px;
  left: 0;
  right: 0;
  bottom: 34px;
  overflow: auto;
  padding: 44px;
  .flex-holder {
    display: flex;
    justify-content: space-between;
    .column {
      width: 48%;
    }
  }
  h1, h2 {
    margin: 0.4em 0 .8em 0;
    font-weight: 500;
  }
  h3, h4 {
    margin: .4em 0 .7em 0;
    font-weight: 500;
    color: #1e43f6;
  }
  p {
    font-size: .9em;
    padding: 0 0 1em 0;
    line-height: 150%;
  }
  .contact-info {
    margin: 17px 53px;
    p {
      line-height: 165%;
      font-size: 1em;
    }
    .person {
      font-weight: 500;
    }
    .person-title {
      font-style: italic;
      font-weight: 300;
    }
  }
}
.title {
  font-size: 18px;
  margin: 0;
}
.page-content {
  height: 100%;
  background: white;
  padding: 20px 10px;
}
.footer {
  position: absolute;
  bottom: 0;
  top: auto;
  width: 100%;
  height: 33px;
  z-index: 500;
  background-color: #663230;
  p {
    font-size: .8em;
    margin: 8px 35px;
    color: #e3dac8;
  }
}
/* login page */
.module-login {
  background-color: white;
  border: 2px solid #e7c072;
  padding: 44px 53px;
  width: 35vw;
  margin: 62px auto;
  border-radius: 8px;
  box-shadow: 0 0 13px 0px #00000066;
  min-width: 500px;
  h1 {
    font-size: 1.8em;
    margin: 0 0 25px 0;
    font-weight: 500;
    color: #92423e;
  }
  .has-plain-anchor {
    justify-content: space-between;
    .plain-anchor-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 44px;
      .plain-anchor {
        text-align: right;
        font-size: .85em;
      }  
    }
  }
  .mb-3 {
    margin: 0 0 21px 0;
    .k-label {
      font-size: .8em;
      font-style: italic;
      color: #252525;
      margin-left: 9px;
    }
    .plain-anchor {
      text-align: center;
      display: block;
    }
  }
}
.le-newUser {
  margin: 0 0 8px 0;
  button {
    padding: 8px 80px;
    border: 1px solid #bb9443;
  }
}
.pass-reqs {
  padding: 4px 26px 18px 26px;
  h4 {
    font-weight: 500;
    margin: 17px 0 8px 0;
  }
  ul {
    margin: 0;
  }
  p, li {
    font-size: .9em;
    line-height: 1.6;
  }
}

