@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html, body, #root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
}
a, .plain-anchor {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
