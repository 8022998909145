@import 'https://js.arcgis.com/4.24/@arcgis/core/assets/esri/themes/light/main.css';

.mapDiv {
  padding: 0;
  margin: 0;
  /*height: 500px;
  width: 700px;*/
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 34px;
}

.map-button {
  height: 25px;
  width: 25px;
  padding: 10px;
}

.map-button-closed {
  background-color: #023a7a;
}

.map-button-open {
  background-color: #95c0f1;
}